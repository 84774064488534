// @import url(menu.css);
// @import url(form.css);
// @import url(fancy.css);
// @import url(font.css);
// @import url(validate.css);
// @import url(supersized.css);

@import "menu";
@import "form";
@import "fancy";
@import "validate";

.clear{ clear: both; }
.hide{ display: none; }

#galerie{ display:none; }

#wrapper{ position:relative; }


/* -- ACCUEIL -- */

/*body.accueil #wrapper{ overflow:hidden; }*/

#home {
	margin: 250px -460px 0 -522px;
	position: relative;
	overflow: hidden;
}

.repere {
	width: 1070px;
	margin: 0 auto;
	height: 0;
	position: relative;
}

h1.home {
	position: fixed;
	top: -150px;
	left: 50%;
	margin: 0 0 0 -250px;
	text-indent: -5000px;
	/*width:1070px;*/
	width: 500px;
	height: 119px;
	background: url(../img/index/logo.gif) no-repeat center 0;
	z-index: 1;
	/*margin:55px auto;*/
}

.left {
	float: left;
	width: 522px;
}

/*.right {
	float: right;
	width: 460px;
	padding: 0 0 0 88px;
}*/

#home ul li { display:inline; }

#home .left ul li img{ 
	display: none;
	float: left;
	margin: 20px 0 0 20px; 
}

#home ul li a {
	float: left;
	width: 105px;
	border: 1px solid #d5d5d6;
	border-width: 0 0 1px 0;
	padding: 10px 0;
	color: #8d2222;
	font-size: 1.5em;
	margin: 20px 0 30px 0;
	font-family: 'PillGothic300mgThRegular', serif;
}

#home ul li a:hover { color:#000; }

#home p.img{ 
	background: url(../img/index/actu.gif) no-repeat 0 0; 
	padding: 0 10px 10px 0; 
	margin: 0; 
	position: relative;
	top: auto;
	left: auto;
}

#home p.img img{ float: none; margin: 0; }

// .actu { width: 275px; }

#home p.footer{
	font-size :0.9em;
	color: #9b9c9e;
	margin: 50px 0 0 0;
}

#home p.footer a { color:#9b9c9e; }
#home p.footer a:hover { color:#000; }

/* -- COLONNE DE DROITE -- */
/*#right {
	position:absolute;
	right:0;
	top:77px;
	width:315px;
	padding:25px 30px;
	background:url(../img/blanc.png);
}

#right p { font-size:0.9em; }*/

.diapo {
	border: 1px solid #d1cec8;
	border-width:1px 0 0 0;
	/*width:230px;*/
	margin: 20px 0;
	padding: 20px 0 0 0;
	overflow: hidden;
}

.contenu { 
	border-width:0 0 1px 0; 
	padding:0 0 20px 0;
}

.nav{ height:23px; margin:10px 0 0 0; }
.nav li{ display:inline; }
.nav li a{
	float:left;
	display:block;
	width:25px;
	height:23px;
	background:url(../img/nav.png) no-repeat 0 0;
}
.nav li a:hover{ background-position:0 -23px; }
.nav li a.next{ background-position:-25px 0; float:right; }
.nav li a.next:hover{ background-position:-25px -23px; }
.nav li a.none, .nav li a.none:hover{ background-position:50px 50px; cursor:default; }

ul.img{ clear:both; height:60px; margin:10px 0 0 0; width:10000px; position:relative; }
ul.img li{ display:inline; position:relative; }
ul.img li img{ display:block; }
ul.img li a{
	display:block;
/*	width:130px;*/
	width:100px;
	float:left;
	background:red;
	margin:0 5px 0 0;
	position:relative;
}
ul.img li a span{
	position:absolute;
	background:url(../img/zoom.png) no-repeat 0 0;
	top:0;
	left:0;
	display:none;
	width:100%;
	height:100%;	
}
ul.img li a.selected span, ul.img li a:hover span, ul.img li a.prevslide span{ display:block; }

h2 a.zoom, h2 a.tarif{
	border:1px solid #d1cec8;
	border-width:1px 0;
	padding:20px 0;
	display:block;
	color: #58595b;
}
h2 a.zoom:hover, h2 a.tarif:hover{ color:#8d2222; }

p.dispo{ margin:20px 0; }
p.dispo strong{ color:#8d2222; }
p.dispo a{ color:#58595b; }
p.dispo a:hover{ color:#8d2222; }

/* -- FULL PAGE -- */
// #full {
// 	position: absolute;
// 	left: 0;
// 	top: 77px;
// 	padding: 25px 30px;
// }

p.img{ 
	position:fixed;
	bottom:20px;
	left:30px;
	z-index:10;
}

p.img img{ float:left; margin:0 10px 0 0; }

ul.presse li{ margin:10px 0; }

/* -- PRIX -- */
table{ border-collapse:collapse;}
table td{ padding:3px 5px; }
table th{padding:15px 5px 3px 5px;color:#fff; font-weight:normal; background-color:transparent; color:#222; }
table th span{font-weight:bold; color:#777;}
td{ text-align:right; background-color:#fff;border:1px solid #ccc; }
.alignleft{ text-align:left; }
tr.gris td{ background-color:#ebf0f5; }

/* -- ACTUS -- */

// .actu #full, .photo #full { overflow: hidden; }

/*.actu .nav li a{ background-color:#fff; }*/

.news h2 { color:#8d2222; }
p.image {
	/*background:url(../img/news.gif) no-repeat right bottom; */
	padding:0 20px 16px 0;
}

/* -- GALERIE PHOTOS -- */

// .photo .news div { margin:30px 30px 0 0; }

// .photo #right{ width:210px; }
.photo #right h1 span { display:block; }

.photo #right ul.nav { display:none; }