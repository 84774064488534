
/***** OPEN SANS *****/
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i');

/***** PILL GOTHIC Regular *****/
@font-face {
    font-family: 'PillGothic300mgThRegular';
    src: url('../font/pillgothic300mg-thin-webfont.eot');
    src: url('../font/pillgothic300mg-thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/pillgothic300mg-thin-webfont.woff') format('woff'),
         url('../font/pillgothic300mg-thin-webfont.ttf') format('truetype'),
         url('../font/pillgothic300mg-thin-webfont.svg#PillGothic300mgThRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}