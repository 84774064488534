/* 
##     ##  ########      ###     ########   ########   ########   
##     ##  ##           ## ##    ##     ##  ##         ##     ##  
##     ##  ##          ##   ##   ##     ##  ##         ##     ##  
#########  ######     ##     ##  ##     ##  ######     ########   
##     ##  ##         #########  ##     ##  ##         ##   ##    
##     ##  ##         ##     ##  ##     ##  ##         ##    ##   
##     ##  ########   ##     ##  ########   ########   ##     ##  
*/
/***** NAV-TOGGLE *****/
.nav-toggle {
	width: 50px;
	height: 50px;
	top: 0;
	right: 0;
	position: fixed;
	z-index: 20;

	@include mq($from: tablet) {
		width: 60px;
		height: 60px;
	}

	@include mq($from: desktop) {
		display: none;
	}
}

@mixin line {
	display: inline-block;
	width: 18px;
	height: 3px;
	background-color: rgba($white, 0.8);
	transition: 0.2s ease-in-out;

	@include mq($from: tablet) {
		width: 20px;
	}
}

.lines-button {
	position: absolute;
	height: auto;
	width: auto;
	right: 15px;
	top: 7px;
	z-index: 101;
	display: inline-block;
	transition: .3s;
	cursor: pointer;
	user-select: none;
	padding: 0;

	@include mq($from: tablet) {
		right: 20px;
		top: 12px;
	}

	.no-touch .nav-toggle:hover &:not(.close) .lines, 
	.no-touch .nav-toggle:hover & .lines:before, 
	.no-touch .nav-toggle:hover & .lines:after {
		background-color: $white;
	}

	@include mq($from: desktop) {
		display: none;
	}
}

.lines {
	@include line;
	position: relative;

	&:before, &:after {
		@include line;
		position: absolute;
		left: 0;
		content: '';
		transform-origin: center center;
	}

	&:before { top: 6px; }
	&:after { top: -6px; }
}

.lines-button.close .lines {
	background-color: transparent;

	&:before, &:after {
		transform-origin: 50% 50%;
		top: 0;
		width: 20px;
		background-color: $white;
	}
	&:before {
		transform: rotate3d(0,0,1, 45deg); 
	}
	&:after {
		transform: rotate3d(0,0,1, -45deg); 
	}
}

/******* TOP *******/
#top {
	background: url(../img/black.png);
	height: 50px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10;

	@include mq($from: tablet) {
		height: 60px;
	}

	@include mq($from: desktop) {
		height: 66px;
	}

	@include mq($from: wide) {
		height: 77px;
	}

	#logo {
		float: left;
		margin: 14px 0 14px 12px;

		@include mq($from: tablet) {
			margin: 18px 0 16px 14px;
		}

		@include mq($from: desktop) {
			margin: 21px 0 21px 15px;
		}

		@include mq($from: wide) {
			margin: 23px 0 22px 20px;
		}

		@include mq($from: xwide) {
			margin: 21px 0 20px 20px;
		}

		a {
			display: block;
			width: 220px;
			height: 22px;
			background-image: url(../img/logo.png);
			background-size: 220px 22px;
			background-repeat: no-repeat;
			text-indent: -5000px;

			@include retina {
				background-image: url(../img/logo@2x.png);
				background-size: 220px 22px;
			}

			@include mq($from: tablet) {
				width: 260px;
				height: 26px;
				background-size: 260px 26px;
			}

			@include mq($from: wide) {
				width: 320px;
				height: 32px;
				background-size: 320px 32px;
			}

			@include mq($from: xwide) {
				width: 362px;
				height: 36px;
				background-size: 362px 36px;
			}
		}
	}
}

/******* MENU *******/

#menu {
	float: right;
	text-align: right;
	z-index: 115;

	@include mq($until: desktop) {
		display: block;
		position: fixed;
		top: 50px;
		bottom: 0;
		left: -100%;
		width: 100%;
		background-color: rgba($black, 0.85);
		text-align: left;

		transition: left 0.35s ease-in-out;

		&.open {
			left: 0;

			@include mq($until: desktop) {
				overflow-y: scroll;
			}
		}
	}

	@include mq($from: desktop) {
		margin: 0 20px 0 0;
		float: right;
	}

	> ul {

		@include mq(mobileLandscape, tablet) {
			width: auto;
			float: left;
		}

		> li {
			display: inline;

			@include mq($until: desktop) {
				display: block;
			}

			a {
				display: inline-block;
				cursor: pointer;
				color: #fff;
				font-size: 1.2em;
				text-decoration: none;
				text-transform: uppercase;
				padding: 0 5px;

				@include mq($until: desktop) {
					padding: 0;
				}

				&:after {
					display: block;
					content: "";
					height: 1px;
					width: 0;
					background-color: rgba($white, 0.75);

					transition: width 0.2s ease-in-out;
				}

				&:hover, &.selected {
					background: #fff;
					color: #373739;

					transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

					@include mq($until: desktop) {
						background: transparent;
						color: $white;

						&:after {
							width: 100%;
						}
					}
				}
			}

			&.lang a {
				padding: 0 3px;
				color: #868585;

				@include mq($until: desktop) {
					padding: 0;
				}

				&.select {
					color: #fff;

					&:hover {
						color: #000;
					}
				}
			}

			> ul {
				position: fixed;
				right: 0;
				top: 77px;
				background: #fff;
				display: none;
				padding: 10px;
				z-index: 10;

				@include mq(desktop, wide) {
					top: 66px;
				}

				@include mq($until: desktop) {
					display: block;
					background-color: transparent;
					position: relative;
					top: auto;
					right: auto;
					padding: 0;
					height: auto;
				}

				> li {
					display: inline;

					@include mq($until: desktop) {
						display: block;
					}

					a {

						@include mq($until: desktop) {
							text-align: left;
							margin: 0;
						}

						img {

							@include mq($until: desktop) {
								display: none;
							}
						}
					}
				}
			}
		} /* li */

		&.firstMenu {
			margin: 15px 0 0 0;

			@include mq($until: desktop) {
				margin: 20px 12px;
			}

			@include mq(mobileLandscape, tablet) {
				margin-right: 40px;
			}

			@include mq($from: wide) {
				margin: 20px 0 0 0;
			}

			li {

				@include mq($until: desktop) {
					line-height: 24px;
				}

				a {
					font-size: 0.8em;
					font-style: italic;

					@include mq($until: desktop) {
						font-size: 1em;

						&:after {
							margin-top: -3px;
						}
					}
				}
			}
		}

		&.secondMenu {

			@include mq($until: desktop) {
				margin: 20px 12px;
			}

			> li {
				@include mq($until: desktop) {
					height: auto;
					margin-bottom: 10px;
				}

				a {
					height: 40px;
					border: 0;

					@include mq($until: desktop) {
						height: auto;
						margin-top: 3px;

						&:after {
							margin-top: 2px;
						}
					}

					@include mq($from: desktop) {
						@include font-size(12);
						height: 34px;
					}

					@include mq($from: wide) {
						@include font-size(14);
						height: 40px;
					}

					@include mq($from: wide) {
						padding: 0 7px;
					}

					@include mq($from: xwide) {
						padding: 0 10px;
					}

					&.last {
						padding-right: 5px;
					}

					&.selected {
						// border-bottom: 2px solid #fff;

						@include mq($until: desktop) {
							border-bottom: none;
						}
					}

					@include mq($until: desktop) {

						&:hover {

							&:after {
								margin-top: 2px;
							}
						}
					}
				}

				> ul {
					@include clearfix;

					@include mq($until: desktop) {
						display: block;
						height: auto;
					}

					> li {

						@include mq($until: desktop) {
							width: auto;
						}

						a { 
							color: #58595b; 
							font-size: 1em; 
							text-transform: none; 
							height: auto; 
							text-align: center;
							padding: 10px 15px;
							display: block;
							float: left;
							line-height: 1em;
							width: 105px;

							@include mq($until: desktop) {
								display: inline-block;
								padding: 2px 0;
								float: none;
								width: auto;
								text-align: left;
								color: #bbb;
								line-height: 1.2em;
							}

							&:hover,
							&.selected {
								background: #efefef;

								@include mq($until: desktop) {
									background-color: transparent;

									&:after {
										margin-top: 2px;
										background-color: rgba($white, 0.35);
									}
								}
							}

							img {
								display: block;

								@include mq($until: desktop) {
									display: none;
								}
							}
		 				}
		 			}
	 			}
			}
		}

	} /* ul */
}

a.btn-resa {
	width: 100%;
	position: fixed;
	top: 50px;
	background-color: #a41c1c;
	text-align: center;

	transition: background-color 0.3s ease-in-out;

	@include mq($until: desktop) {
		&.until-desktop {
			display: block;
		}
	}

	@include mq($from: tablet) {
		top: 60px;
	}

	@include mq($from: desktop) {
		width: auto;
		height: 100%;
		line-height: 66px;
		position: relative;
		top: 0;
		float: right;
		padding: 0 15px;
	}

	@include mq($from: wide) {
		line-height: 77px;
		padding: 0 20px;
	}

	&:after {
		display: block;
		content: "";
		width: 0;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: darken(#a41c1c, 5%);

		transition: width 0.3s ease-in-out;		
	}

	&:hover,
	&:active {
		
		&:after {
			width: 100%;
		}
	}

	span {
		color: $white;
		text-transform: uppercase;
		font-style: italic;
		line-height: 28px;
		font-family: 'Open Sans';
		@include font-size(12);
		font-weight: 600;
		@include vertical-align();
		position: relative;
		z-index: 10;

		@include mq($from: tablet) {
			line-height: 32px;
		}

		@include mq($from: desktop) {
			line-height: 66px;
			@include font-size(13);
		}

		@include mq($from: wide) {
			line-height: 77px;
		}

		&:before {
			display: inline-block !important;
			content: '';
			width: 16px;
			height: 16px;
			background: transparent url('../img/icon/calendar.svg') 0 0 no-repeat;
			margin: -6px 8px 0 0;
			@include vertical-align-inner();

			@include mq($from: desktop) {
				width: 18px;
				height: 18px;
				margin-right: 14px;
			}
		}
	}	
}

/* 
##      ## ########      ###     ########   ########   ########   ########   
##  ##  ## ##     ##    ## ##    ##     ##  ##     ##  ##         ##     ##  
##  ##  ## ##     ##   ##   ##   ##     ##  ##     ##  ##         ##     ##  
##  ##  ## ########   ##     ##  ########   ########   ######     ########   
##  ##  ## ##   ##    #########  ##         ##         ##         ##   ##    
##  ##  ## ##    ##   ##     ##  ##         ##         ##         ##    ##   
 ###  ###  ##     ##  ##     ##  ##         ##         ########   ##     ##  
*/
#wrapper {
	position: relative;
	@include clearfix;
	width: 100%;
	height: auto;
	margin-top: 218px;

	@include mq($from: tablet) {
		margin-top: 372px;
	}

	@include mq($from: desktop) {
		margin-top: 66px;
	}

	@include mq($from: wide) {
		margin-top: 77px;
	}

	.actu &,
	.photo &,
	.visite-virtuelle &,
	.video &{
		@include mq($until: tablet) {
			margin-top: 78px;
		}
	}
	
	.visite-virtuelle &,
	.video &{
		@include mq(tablet) {
			margin-top: 92px;
		}
		@include mq(desktop) {
			margin-top: 66px;
		}
		@include mq($from: wide) {
			margin-top: 77px;
		}
	}
	
	
	.photo-detail & {
		@include mq($until: tablet) {
			margin-top: 278px;
		}

		@include mq(tablet, desktop) {
			margin-top: 512px;
		}
	}
}

.credit {

	.title-lev1 {
		font-family: $font-title;
		color: $color-1;
		@include font-size(22);
	}
}

.mentions {

	.title-lev1 {
		font-family: $font-title;
		color: $color-1;
		@include font-size(22);
	}

	a {
		display: block;
		padding: 10px 0 0 0; 
	}
}

/* 
##     ##     ###     ####  ##    ##   
###   ###    ## ##     ##   ###   ##   
#### ####   ##   ##    ##   ####  ##   
## ### ##  ##     ##   ##   ## ## ##   
##     ##  #########   ##   ##  ####   
##     ##  ##     ##   ##   ##   ###   
##     ##  ##     ##  ####  ##    ##   
*/
/***** SUPERSIZED *****/
#supersized {
	
	.visite-virtuelle &{
		display: none;
	}

	@include mq($until: desktop) {
		@include clearfix;
		position: absolute;
		width: 100%;
		height: 140px;
		top: 78px;
		left: auto;

		.photo-detail & {
			height: 200px;
		}

		@include mq($from: tablet) {
			height: 280px;

			.photo-detail & {
				height: 420px;
			}
		}
	}

	li {

		@include mq($until: desktop) {
			display: block;
			position: absolute;
			top: -70px;
			width: 100%;
			max-width: 100%;
			height: auto;
			box-sizing: border-box;
			overflow: hidden;

			.photo-detail & {
				top: -10px;
			}

			@include mq($from: tablet) {
				top: -140px;

				.photo-detail & {
					top: -40px;
				}
			}
		}

		a {
			@include mq($until: desktop) {
				display: block;
				width: 100%;
				max-width: 100%;
				height: auto;
				@include clearfix;
			}

			img {

				@include mq($until: desktop) {
					width: 100%;
					max-width: 100%;
					height: auto;
					top: 0 !important;
					left: 0 !important;
				}
			}
		}
	}
}

/* 
##     ##   #######   ##     ##  ########   
##     ##  ##     ##  ###   ###  ##         
##     ##  ##     ##  #### ####  ##         
#########  ##     ##  ## ### ##  ######     
##     ##  ##     ##  ##     ##  ##         
##     ##  ##     ##  ##     ##  ##         
##     ##   #######   ##     ##  ########   
*/
.title-home {
	font-family: $font-title;
	color: $color-1;
	@include font-size(24);
	text-align: center;
	line-height: 1em;
	margin-bottom: 20px;

	@include mq($from: tablet) {
		@include font-size(30);
		margin-bottom: 30px;
	}

	@include mq($from: wide) {
		@include font-size(34);
	}

	span.subtitle {
		display: block;
		color: $black;
		@include font-size(18);

		@include mq($from: tablet) {
			@include font-size(20);
		}

		@include mq($from: wide) {
			@include font-size(22);
		}
	}

	span.stars {
		display: block;
		width: 100%;
		height: 14px;
		background: transparent url('../img/stars.png') left top no-repeat;
		background-size: 72px 14px;
		background-position: 50% 0;

		position: relative;
		left: 0;
		margin: 10px auto 0 auto;

		@include mq($from: tablet) {
			margin-top: 12px;
		}

		@include retina {
			background-image: url('../img/stars@2x.png');
			background-size: 72px 14px;
		}

		&:before,
		&:after {
			display: block;
			content: "";
			width: 50%;
			width: calc(50% - 50px);
			height: 1px;
			background-color: $color-1;
			background-color: rgba($color-1, 0.3);

			position: absolute;
			top: 6px;
		}

		&:before {
			left: 0;
		}

		&:after {
			right: 0;
		}
	}
}

/***** VIDEO BACKGROUND *****/

.vimeo-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	pointer-events: none;
	overflow: hidden;

	iframe {
		width: 100vw;
		height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
		min-height: 100vh;
		min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	@include mq($until: desktop) {
		display: none;
	}
}

.actu-home {
	margin: 20px auto;

	@include mq($from: desktop) {
		margin-top: 2.4em;
	}

	@include mq($from: wide) {
		margin-top: 2.6em;
	}

	.title-lev2 {
		color: $color-1;
		margin-bottom: 0.8em;
	}

	.actu--item {
		background-color: $white;
		@include clearfix;
		position: relative;
		width: 100%;

		@include mq(mobileLandscape, desktop) {
			max-width: 420px;
		}

		&:hover {
			cursor: pointer !important;
		}

		img,
		.actu--item__info {
			float: left;
			width: percentage(1/2);

			@include mq(desktop, wide) {
				float: none;
				width: 100%;
				height: auto;
			}
		}

		.actu--item__info {
			box-sizing: border-box;
			padding: 10px 20px 20px 20px;

			@include mq($until: mobileLandscape) {
				padding: 5px 15px 15px 15px;
			}

			@include mq($until: desktop) {
				border: 1px solid #dfdfdf;
				height: 100%;
				position: absolute;
				left: 50%;
			}

			@include mq($from: wide) {
				padding: 20px;
			}

			p.date {

				@include mq($until: mobileLandscape) {
					@include font-size(11);
				}
			}

			.title {
				color: $color-1;
				@include font-size(12);
				line-height: 1em;

				@include mq($from: mobileLandscape) {
					@include font-size(13);
				}

				@include mq($from: wide) {
					@include font-size(14);
				}
			}
		}
	}
}

.facebook-plugin {

	@include mq($until: desktop) {
		display: none !important;	
	}

	display: block;
	@include clearfix;
}

.resa-home {
	display: block;
	margin: 50px auto 0 auto;
	background-color: $color-1;
	position: relative;
	padding: 20px 12px 30px 12px;
	text-align: center;

	@include mq($from: mobileLandscape) {
		margin-top: 80px;
	}

	@include mq($from: tablet) {
		margin-top: 40px;
		padding: 20px;

		background: $color-1 url('../img/illu-bg.png') bottom right no-repeat;

		@include retina {
			background-image: url('../img/illu-bg@2x.png');
			background-size: 215px 150px;
		}
	}

	@include mq($from: desktop) {
		margin-top: 60px;
	}

	@include mq($from: wide) {
		margin-top: 80px;
	}

	&:before {
		display: block;
		content: "";
		width: 100%;
		height: 60px;
		background: transparent url('../img/frise-resa.png') left bottom no-repeat;
		background-size: 100% auto;
		position: absolute;
		bottom: 100%;
		left: 0;

		@include mq($from: mobileLandscape) {
			height: 70px;
		}

		@include mq(tablet, desktop) {
			display: none;
		}

		@include mq($from: desktop) {
			height: 30px;
		}

		@include mq($from: wide) {
			height: 50px;
		}

		@include retina {
			background-image: url('../img/frise-resa@2x.png');
			background-size: 100% auto;
		}
	}

	.resa-home__title {
		font-family: $font-title;
		color: $white;
		@include font-size(16);
		margin-bottom: 1em;
		text-align: left;

		@include mq($from: tablet) {
			@include font-size(20);
			margin-bottom: 0.8em;
		}
	}

	p {
		color: $white;
		text-align: left;
	}

	.resa-home__btn {
		display: inline-block;
		margin: 18px auto 0 auto;
		width: auto;
		background-color: #efefef;
		position: relative;

		line-height: 36px;
		padding: 0 20px;

		@include mq($from: tablet) {
			margin: 30px auto;
			line-height: 48px;
			padding: 0 35px;
		}

		@include mq($from: desktop) {
			margin: 20px auto 30px auto;
			line-height: 40px;
			padding: 0 25px;
		}

		@include mq($from: wide) {
			margin: 25px auto 40px auto;
			line-height: 48px;
			padding: 0 35px;
		}

		&:after {
			display: block;
			content: "";
			width: 0;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: $white;

			transition: width 0.3s ease-in-out;		
		}

		span {
			display: block;
			position: relative;
			z-index: 10;

			font-family: $font-base;
			font-style: italic;
			font-weight: 700;
			color: $color-1;
			text-transform: uppercase;
			text-align: center;

			transition: color 0.3s ease-in-out;

			@include mq($from: tablet) {
				@include font-size(13);
			}	
		}

		&:hover,
		&:active {
			
			&:after {
				width: 100%;
			}

			span {
				color: lighten($color-1, 8%);
			}
		}
	}
}


/* 
 ######    ####  ########   ########   ########      ###     ########   
##    ##    ##   ##     ##  ##         ##     ##    ## ##    ##     ##  
##          ##   ##     ##  ##         ##     ##   ##   ##   ##     ##  
 ######     ##   ##     ##  ######     ########   ##     ##  ########   
      ##    ##   ##     ##  ##         ##     ##  #########  ##   ##    
##    ##    ##   ##     ##  ##         ##     ##  ##     ##  ##    ##   
 ######    ####  ########   ########   ########   ##     ##  ##     ##  
*/
#right {
	width: 100%;
	position: relative;
	box-sizing: border-box;
	padding: 20px 12px 12px 12px;
	@include clearfix;

	@include mq($until: desktop) {
		height: auto !important;
	}

	@include mq($from: desktop) {
		position: absolute;
		right: 0;
		width: 315px;
		padding: 25px 30px;
		background: url(../img/blanc.png);

		min-height: calc(100vh - 66px);
	}

	@include mq($from: wide) {
		width: 480px;
		min-height: calc(100vh - 77px);
	}
	
	.image {
		img {
			@media screen and (max-width: 1200px) {
				margin-left: 0px;
			}
			@media screen and (max-width: 450px) {
				margin-left: 50px;
			}
			margin-top: 10px;
			margin-left: 75px;
		}
	}

	.accueil & {	
	}
}

.left {
	img {
		position: absolute;
    	width: 100%;
    	height: 140px;
    	top: 78px;
    	left: auto;
		@media (max-width: 63.99em) and (min-width: 48em) {
			height: 280px;
		}
		
		@include mq($from: desktop) {
		    display: block;
		    position: fixed;
		    left: 0;
		    top: 0;
		    overflow: hidden;
		    z-index: -999;
		    height: 100%;
		    width: 100%;
		}
	}
}

.diapo {

	@include mq($until: desktop) {
		display: none;
	}

	.accueil & {
		display: none;
	}
}

.img-container {
	@include clearfix;
	width: 100%;
	width: calc(100% + 10px);
	margin: 25px auto 10px -5px;

	a {
		width: percentage(1/2);
		display: block;
		float: left;
		padding: 5px;
		box-sizing: border-box;

		@include mq($from: mobileLandscape) {
			width: percentage(1/4);
		}

		@include mq($from: desktop) {
			width: percentage(1/2);
		}

		@include mq($from: wide) {
			width: percentage(1/3);
		}

		img {
			width: 100%;
			height: auto;
			margin: 0;

			&.img-plan {
				margin-top: 3px;
			}
		}
	}
}

/* 
   ###      ######    ########   ##     ##  
  ## ##    ##    ##      ##      ##     ##  
 ##   ##   ##            ##      ##     ##  
##     ##  ##            ##      ##     ##  
#########  ##            ##      ##     ##  
##     ##  ##    ##      ##      ##     ##  
##     ##   ######       ##       #######   
*/
#full {
	box-sizing: border-box;
	padding: 20px 12px;

	@include mq($from: tablet) {
		padding: 30px 20px 20px 20px;
	}

	@include mq($from: desktop) {
		padding: 40px 30px 20px 30px;
	}
	.btn {
		margin-top: 5px;
		float: right
	}
	
	&.full-nm{
		padding: 0;
	}
}

.news {
	position: relative;
	width: calc(100% + 20px);
	margin: 10px auto 0 -10px;

	.news-item,
	.album {
		float: left;
		margin: 0 auto;
		padding: 5px 10px;
		width: 100%;
		box-sizing: border-box;

		@include mq($from: mobileLandscape) {
			width: percentage(1/2);
			padding: 10px;
		}

		@include mq($from: tablet) {
			width: percentage(1/3);
		}

		@include mq($from: desktop) {
			width: percentage(1/4);
		}

		@include mq($from: wide) {
			width: percentage(1/5);
		}

		> div {
			background: #efefef;
			padding: 12px;
			overflow: hidden;
			box-sizing: border-box;

			@include mq($from: tablet) {
				padding: 15px;
			}

			.image {
				padding: 0;
				margin: 0;

				img {
					width: 100%;
					height: auto;
				}
			}

			iframe {
				width: 100%;
				height: auto;
			}

			.title-album {
				margin-top: 5px;
				@include font-size(18);
				line-height: 1.1em;
			}
		}
	}
}

/* 
########   ##     ##   #######   ########    #######   
##     ##  ##     ##  ##     ##     ##      ##     ##  
##     ##  ##     ##  ##     ##     ##      ##     ##  
########   #########  ##     ##     ##      ##     ##  
##         ##     ##  ##     ##     ##      ##     ##  
##         ##     ##  ##     ##     ##      ##     ##  
##         ##     ##   #######      ##       #######   
*/
/***** DÉTAIL PHOTO *****/
.photo {

	ul.img {
		height: auto;
		width: 100%;
		width: calc(100% + 6px);
		margin: 0 auto 0 -3px;
		@include clearfix;

		li {
			display: inline-block;
			width: percentage(1/3);
			height: auto;
			box-sizing: border-box;
			float: left;
			padding: 3px;
			margin: 0;

			@include mq($from: mobileLandscape) {
				width: percentage(1/6);
			}

			@include mq($from: tablet) {
				width: percentage(1/8);
			}

			@include mq($from: desktop) {
				width: percentage(1/3);
			}

			@include mq($from: wide) {
				width: percentage(1/4);
			}

			a {
				display: block;
				width: auto;
				float: left;
				margin: 0 auto;
			}

			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
	}
}


/* 
########   ########   ####  ##     ##  
##     ##  ##     ##   ##    ##   ##   
##     ##  ##     ##   ##     ## ##    
########   ########    ##      ###     
##         ##   ##     ##     ## ##    
##         ##    ##    ##    ##   ##   
##         ##     ##  ####  ##     ##  
*/
.encart-prix {

	@include mq($from: desktop) {
		padding: 20px;
		background: url(../img/blanc.png);
		max-width: 964px;
	}

	&.encart-prix--service {
		margin-top: 30px;
	}
}

.WidgetDirect {
	@include clearfix;

	@include mq($until: desktop) {
		margin-top: 10px;
	}

	@include mq($from: tablet) {
		width: 100%;
	}

	.OsMoteur.OptMoteurEnLigne {
		box-sizing: border-box;

		@include mq($from: tablet) {
			padding: 15px;
			width: 100%;
		}

		@include mq($from: desktop) {
			width: auto;
		}
	}

	.OsMoteur.OptMoteurEnLigne .OsMoteurCriterisation {

		.OsMoteurCritBloc {

			@include mq($from: tablet) {
				width: 200px !important;

				&.OsMoteurCritere_date .OsMoteurCritereContenu input {
					width: 180px;
				}
			}
		}

		select {
			width: 100%;
		}
	}

	.OsMoteur .OsMoteurCriterisation input,
	.OsMoteur .OsMoteurCriterisation select {
		width: 100%;
		height: 30px;
		line-height: 28px;
		box-sizing: border-box;
	}

	.OsMoteur.OptMoteurEnLigne .OsWrapSelect {
		box-sizing: border-box;
		height: 30px;
		line-height: 30px;
		padding: 0 8px;

		@include mq($from: tablet) {
			width: 180px;
		}
	}

	.OsMoteurCritere_duree {

		@include mq($until: tablet) {
			margin-top: 10px;
		}
	}

	.OsMoteurCritereContenu {
		@include clearfix;

		input {
			box-sizing: border-box;
		}
	}

	.OsMoteurBoutonRecherche {
		box-sizing: border-box;

		@include mq($until: tablet) {
			margin-top: 10px;
			padding: 0 10px;
		}

		a {
			box-sizing: border-box;
			height: 30px;
			line-height: 30px;
			padding: 0 !important;

			@include mq($from: tablet) {
				width: 180px;
			}
		}
	}
}

.iframe{
	
	position: relative;
	width: 100%;
	
	@include mq($until: tablet) {
		min-height: calc( 100vh - 78px);
	}
	@include mq(tablet) {
		min-height: calc( 100vh - 92px);
	}
	@include mq(desktop) {
		min-height: calc( 100vh - 66px);
	}
	@include mq($from: wide) {
		min-height: calc( 100vh - 77px);
	}
	
	iframe{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: none;
	}
	
}

.player-main{
	position: relative;
	
	@include mq($from: tablet) {
		float: left;
		width: percentage(9/12);
	}
	
	&:after{
		content: "";
		display: block;
		padding-top: 56.25%;
	}
	
	& > iframe{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	
	img{
		transition: opacity 0.3s ease-in-out;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
	}
	
	&.playing{
		img{
			opacity: 0;
			pointer-events: none;
		}
	}
}

.player-thumb{
	
	.player{
		position: relative;
		margin: 10px 0;
		
		float: left;
		width: percentage(1/3);
		
		cursor: pointer;
		
		&:before{
			transition: opacity 0.3s ease-in-out;
			content: "";
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(#000, .5);
			background-image: url('../svg/play.svg');
			background-repeat: no-repeat;
			background-position: center;
			background-size: 28px 28px;
		}
		
		&.playing{
			&:before{
				opacity: 0;
			}
		}
		
		&:after{
			content: "";
			display: block;
			padding-top: 56.25%;
		}
		
		img{
			position: absolute;
			z-index: 0;
			top: 0;
			left: 0;
			width: 100%;
			height: auto;
		}
	}
	
	@include mq($from: tablet) {
		float:left;
		width: percentage(2/12);
		margin-left: percentage(1/12);
		.player{
			width: 100%;
			margin-top: 0;
		}
	}
}