html { font-size: 62.5%; }

$font-base:  'Open Sans', sans-serif;
$font-title: 'PillGothic300mgThRegular', sans-serif;
$font-icon:  'mcube';
$image-path: '/images/';

$color-1: #a41c1c;

$color-bg:          #fff;
$color-text:        $color-1;
$color-text-light:  #fff;

$color-cancel: #bc2121;

$color-link: red;
$color-link-hover: blue;

$color-border: #ddd;

$color-facebook:    #3b5998;
$color-twitter:     #00c8fa;
$color-pinterest:   #cb2027;
$color-linkedin:    #0073b2;
$color-google-plus: #dd4b39;

$white: #fff;
$black: #000;

$grid-gutter-base:    20px / 2;
$grid-gutter-tablet:  20px / 2;
$grid-gutter-desktop: 30px / 2;
$grid-gutter-wide:    30px / 2;