#cookie-bar {background:#111111; background:rgba(#111111,.9);  height:auto; line-height:24px; color:#eeeeee; text-align:center; padding:10px;}
#cookie-bar.fixed {position:fixed; top:0; left:0; width:100%;}
#cookie-bar.fixed.bottom {bottom:0; top:auto;}
#cookie-bar p {margin:0; padding:0;}
#cookie-bar a {color:#ffffff; display:inline-block; border-radius:0px; text-decoration:none; padding:0 6px; margin-left:8px;}
#cookie-bar .cb-enable {background:#1abc9c; transition: background-color 0.2s ease-in-out;}
#cookie-bar .cb-enable:hover {background:darken(#1abc9c,10%);}
#cookie-bar .cb-disable {background:#e74c3c; transition: background-color 0.2s ease-in-out;}
#cookie-bar .cb-disable:hover {background:darken(#e74c3c,10%)}
#cookie-bar .cb-policy {background:#0033bb;}
#cookie-bar .cb-policy:hover {background:#0055dd;}