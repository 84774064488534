/*#content .gauche .bloc .formulaire{
	background: url('../img/loader.gif') center no-repeat;
}*/

select, input, textarea{
	display: block;
	width: 400px;
	font: 12px Arial, verdana, Helvetica, sans-serif;
	color: #565658;
	resize: none;
	border: 1px solid #e5e5e6;
	padding: 3px;
	margin: 0;
	background: #fff;
	float:left;
}

select{ width:408px; }

label{
	display:block;
	float:left;
	color:#0f76bb;
	padding:5px 0;
	width:120px;
}

#_particulier, #_professionnel{ width:20px; height:23px; }

textarea{ height: 90px; }

form p{ clear:both; }

button{
	color: #fff;
	background: #565658;
	cursor: pointer;
	height: 29px;
	border: none;
	padding: 5px;
	font: 12px Arial, verdana, Helvetica, sans-serif;
	margin:0 0 0 120px;
}

button:hover{ background: #0f76bb; }

button:active{ background: #0f76bb; }